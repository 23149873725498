/* added by CRA, useful?
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

@font-face {
  font-family: 'Exo2';
  src: local('gil'), url(./fonts//Exo2-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quattro';
  src: local('gil'),
    /* url(../public/media/fonts/QuattrocentoSans-Regular.ttf) format('truetype'); */
      url(./fonts//QuattrocentoSans-Regular.ttf) format('truetype');
}

:root {
  /* --bgColor: rgb(42, 42, 42); */
  --bgColor: rgb(50, 50, 50);
  --themeColor: rgba(13, 91, 43, 0.9);
}

body {
  background-color: var(--bgColor);
  color: white;
  font-family: 'Quattro';
}

html {
  font-size: 22px;
}

/*//////////////////////////////////

  media queries  
*/
@media (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 1050px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 550px) {
  html {
    font-size: 13px;
  }
}
